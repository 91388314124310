const prefixName = "call-center.call-center";

const callCenterRoute = [
  {
    path: "/:appId/call-center/recording",
    name: `${prefixName}.recording`,
    component: () => import("@/views/call-center/pages/Recording.vue"),
  },
  {
    path: "/:appId/call-center/report",
    name: `${prefixName}.report`,
    component: () => import("@/views/call-center/pages/Report.vue"),
  },
];

export default callCenterRoute;
